@import 'mixins';


::selection{
    background-color: yellow;
    color: black;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.order-button{
    cursor: pointer;
}

h3{
    // background-color: pink;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
}

.site-header{
    width: 100%;
    height: 100px;
    // background-color: rgb(145, 0, 0);
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__logo-block{
        width: 200px;
        height: 100%;
        // background-color: pink;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__mobile-button{
        display: none;
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 0px 2px $main-blue;
        padding: 3px;
        path{
            transition: 0.3s;
            stroke: $main-blue;
        }
    }

    &__mobile-button_active{
        path{
            &:not(:nth-child(2)){
                opacity: 0;
            }

            &:nth-child(2){
                transform: rotate(90deg) translate(0px, -28px);
                // transform: translate(10px);
            }
        }
    }

    &__logo{
        max-width: 80%;
        max-height: 80%;
    }

    &__nav{
        // background-color: pink;
        @include flexRow(space-between);
        align-items: center;
    }

    &__menu{
        // background-color: plum;
        height: 100%;
        list-style: none;
        @include flexRow(space-between);
        align-items: center;
    }

    &__menu-item{
        margin: 0px 10px;
        height: 100%;
        // background-color: orange;
        @include flexColumn(center);
        &:first-child{
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
        }
    }

    &__menu-link{
        text-decoration: none;
        color: black;
        font-size: 20px;
        &:hover{
            color: $main-blue;
        }
    }

    &__order-button{
        width: 200px;
        height: 50px;
        background-color: yellow;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        border-radius: 5px;
        text-decoration: none;
        color: $main-dark;
        font-size: 20px;
        cursor: pointer;
        box-shadow: 0px 5px 0px 0px darken(yellow, 15%);
        transition: 0.1s;
        &:hover{
            background-color: darken(yellow, 5%);
        }
        &:active{
            margin-top: 10px;
            box-shadow: none;
        }
    }
}

.main-section{
    width: 100%;
    height: 600px;
    // background-color: red;
    background-image: url(./../src/img/main.jpg);
    background-attachment: fixed;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__header{
        width: 100%;
        text-align: center;
        font-weight: 300;
        font-size: 46px;
        margin-top: 150px;
        color: white;
    }

    &__order-button{
        width: 250px;
        height: 50px;
        background-color: yellow;
        @include flexRow(center);
        align-items: center;
        text-decoration: none;
        color: $main-dark;
        border-radius: 5px;
        font-size: 20px;
        margin-top: 60px;
        cursor: pointer;
        box-shadow: 0px 5px 0px 0px darken(yellow, 15%);
        transition: 0.1s;
        &:hover{
            background-color: darken(yellow, 5%);
        }
        &:active{
            margin-top: 65px;
            box-shadow: none;
        }
    }
}

.counters-section{
    padding: 50px 0px;
    width: 100%;
    // height: 300px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__counters-container{
        // background-color: pink;
        width: 100%;
        @include flexRow(center);
        // height: 200px;
        margin-top: 50px;
        flex-wrap: wrap;
    }

    &__counter{
        // background-color: plum;
        margin: 0px 20px;
        height: 150px;
        width: 200px;
        @include flexColumn(flex-start);
        text-decoration: none;
    }

    &__counter-value{
        width: 100%;
        // background-color: orange;
        text-align: center;
        font-size: 36px;
        font-weight: 400;
        color: $main-blue;
    }

    &__counter-text{
        // background-color: orange;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        margin-top: 20px;
        color: $main-dark;
    }
}

.popular-section{
    padding: 50px 20px;
    // width: 100%;
    // height: 500px;
    // background-color: red;
    background-color: darken(white, 2%);
    background-image: url(./../src/img/popular-cats/section.png);
    background-size: 20px 20px;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        // background-color: pink;
        text-align: center;
        font-size: 36px;
        font-weight: 300;
    }

    &__cats-container{
        // width: 100%;
        max-height: 330px;
        overflow: hidden;
        margin-top: 50px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cats-container_active{
        max-height: unset;
        overflow: unset;
    }

    &__cat{
        width: 220px;
        height: 150px;
        // background-color: plum;
        margin: 0px 10px 20px 10px;
        position: relative;
        text-decoration: none;
        border-radius: 5px;
        overflow: hidden;
        transition: 0.3s;
        &:hover{
            // transform: scale(1.1);
            img{
                transform: scale(1.2);
            }
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.555);
        }
    }

    &__cat-img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }

    &__cat-cover{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.329);
        color: white;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        font-size: 20px;
        text-shadow: 0px 0px 5px black;
    }
    
    &__buttons-block{
        // background-color: pink;
        height: 60px;
        @include flexRow(center);
    }

    &__button{
        width: 200px;
        height: 50px;
        background-color: yellow;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: $main-dark;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 5px 0px 0px darken(yellow, 15%);
        transition: 0.1s;
        &:hover{
            background-color: darken(yellow, 5%);
        }
        &:active{
            margin-top: 5px;
            box-shadow: 0px 0px 0px 0px darken(yellow, 15%);
        }
    }

}

.workers-section{
    padding:  50px 0px;
    width: 100%;
    // height: 400px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        @include flexColumn(flex-start);
    }

    &__workers-container{
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        // height: 300px;
        margin-top: 50px;
    }

    &__worker{
        @include flexColumn(flex-start);
        align-items: center;
        // background-color: plum;
        width: 200px;
        height: 300px;
        box-shadow: 0px 0px 0px 2px $main-blue;
        cursor: pointer;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        margin: 20px 20px;
        &:hover{
            .workers-section__worker-cover{
                top: 0px;
            }
        }
    }

    &__worker-img-block{
        border-radius: 100px;
        overflow: hidden;
        width: 130px;
        height: 130px;
        margin-top: 30px;
        background-color: orange;
        @include flexRow(center);
        align-items: center;
    }

    &__worker-img{
        max-width: 100%;
        max-height: 100%;
    }

    &__worker-name{
        margin-top: 15px;
        font-size: 20px;
    }

    &__rating{
        width: 100%;
        height: 30px;
        // background-color: orange;
        margin-top: 20px;
        @include flexRow(center);
    }

    &__rating-star{
        width: 30px;
        height: 30px;
        fill: darken(white, 5%);
        margin: 0px 2px;
    }

    &__rating-star_active{
        fill: orange;
    }

    &__worker-cover{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.596);
        position: absolute;
        top: -300px;
        @include flexColumn(flex-start);
        align-items: center;
        transition: 0.5s;
        // border-radius: 5px;
    }

    &__worker-reviews{
        width: 100%;
        // background-color: red;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        color: white;
        margin-top: 20px;
        font-size: 20px;
    }

    &__worker-reviews-contrast{
        color: yellow;
        font-size: 24px;
        margin-left: 5px;
    }

    &__worker-info{
        width: 100%;
        // background-color: red;
        color: white;
        text-align: center;
        margin-top: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }

    &__circle::before{
        display: block;
        content: "\2705";
        @include flexColumn(center);
        padding: 0px;
        font-size: 20px;
        // width: 30px;
        // height: 30px;
        // background-color: yellow;
        margin-left: 5px;
        // margin-right: 10px;
    }

    &__worker-text{
        font-size: 15px;
    }

    &__worker-button{
        width: 150px;
        height: 40px;
        background-color: yellow;
        margin-top: 30px;
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        text-align: center;
        cursor: pointer;
        box-shadow: 0px 5px 0px 0px darken(yellow, 15%);
        transition: 0.1s;
        &:hover{
            background-color: darken(yellow, 5%);
        }
        &:active{
            margin-top: 35px;
            box-shadow: 0px 0px 0px 0px darken(yellow, 15%);
        }
    }
    
}

.questions-section{
    padding: 50px 0px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        margin-bottom: -50px;
    }

    &__content-container{
        width: 100%;
        // height: 450px;
        margin-top: 50px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__questions-container{
        max-width: 600px;
        width: 100%;
        height: 100%;
        // background-color: plum;
        margin: 0px 10px 0px 10px;
        margin-top: 50px;
        @include flexColumn(flex-start);
    }

    &__question-block{
        // width: 100%;
        min-height: 30px;
        // background-color: orange;
        @include flexColumn(flex-start);
        box-shadow: 0px 1px 0px 0px black;
        &:not(:first-child){
            margin-top: 25px;
        }
    }
    
    &__question-header-block{
        padding: 5px;
        @include flexRow(space-between);
        align-items: center;
        // background-color: red;
        cursor: pointer;
        // width: 100%;
        &:not(.questions-section__question-header-block_active){

            &:hover{
                background-color: darken(white, 10%);
            }
        }
    }

    &__question-header-block_active{
        border-radius: 5px 5px 0px 0px;
        background-color: $main-blue;
        color: white;
    }

    &__question-header{
        font-size: 30px;
        font-weight: 400;
    }

    &__question-button{
        path,circle{
            stroke: $main-dark;
        }
    }

    &__question-button_active{
        path{
            &:nth-child(2){
                display: none;
            }
        }
        path,circle{
            stroke: white;
        }
    }

    &__question-text-block{
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
        display: none;
    }

    &__form-container{
        max-width: 290px;
        padding: 5px;
        width: 100%;
        height: 400px;
        // background-color: plum;
        box-shadow: 0px 0px 0px 3px $main-blue;
        margin: 0px 15px 0px 15px;
        margin-top: 50px;
        @include flexColumn(flex-start);
    }
    
    &__form{
        width: 100%;
        height: 100%;
        // background-color: orange;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__form-header{
        font-size: 24px;
        text-align: center;
        @include flexRow(center);
        align-items: center;
        margin-top: 20px;
    }

    &__input{
        width: 200px;
        padding: 0px 0px 0px 5px;
        height: 30px;
        margin-top: 20px;
        outline: none;
        border: 2px solid $main-blue;
    }

    &__button{
        margin-top: 30px;
        width: 205px;
        height: 40px;
        font-size: 18px;
        border: none;
        border-radius: 5px;
        background-color: yellow;
        cursor: pointer;
        box-shadow: 0px 5px 0px 0px darken(yellow, 15%);
        transition: 0.1s;
        &:hover{
            background-color: darken(yellow, 5%);
        }
        &:active{
            margin-top: 35px;
            box-shadow: 0px 0px 0px 0px darken(yellow, 15%);
        }
    }
}

.reviews-section{
    padding: 50px 0px;
    // margin-top: 150px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    background-image: url(./../src/img/popular-cats/section.png);
    background-size: 20px 20px;
    @include flexRow(center);
    // display: none;

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__reviews-container{
        width: 100%;
        // height: 400px;
        margin-top: 40px;
        padding: 50px 0px;
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
    }

    &__review-block{
        width: 240px;
        height: 300px;
        // background-color: plum;
        background-color: white;
        @include flexColumn(flex-start);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.315);
        padding: 5px 30px 15px 30px;
        border-radius: 15px;
        cursor: pointer;
        transition: 0.3s;
        // margin-bottom: 30px;
        margin: 0px 15px 30px 15px;
        
        &:hover{
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.425);
            // transform: scale(1.1);
        }
    }

    &__reviewer-name{
        width: 100%;
        height: 40px;
        // background-color: orange;
        @include flexColumn(center);
        font-size: 24px;
        font-weight: 300;
        color: $main-dark;
    }

    &__review-stars{
        width: 100%;
        height: 40px;
        // background-color: orange;
        // margin-top: 10px;
        @include flexRow(flex-start);
        align-items: center;
    }
    
    &__review-star{
        width: 30px;
        height: 30px;
        fill: $main-blue;
        margin-right: 10px;
        &:hover{
            transform: scale(1.1);
        }
    }

    &__review-cat{
        // background-color: orange;
        margin-top: 10px;
        color: $main-dark;
        text-align: center;
        line-height: 25px;
        font-weight: 600;
        // font-size: 14px;
    }
    &__review-subcat{
        // background-color: orange;
        margin-top: 10px;
        color: $main-dark;
        text-align: center;
        line-height: 25px;
        // font-weight: 600;
        // font-size: 14px;
    }

    &__img-block{
        // background-color: plum;
        margin-top: 10px;
        @include flexRow(center);
        align-items: center;
        height: 150px;
    }

    &__img{
        max-width: 100%;
        max-height: 100%;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }
    }
}

.area-section{
    width: 100%;
    // height: 200px;
    padding: 50px 0px;
    // background-color: red;
    background-color: rgb(236, 236, 236);
    // margin-top: 100px;
    @include flexRow(center);

    &__container{
        width: 1180px;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__areas-toggle-block{
        width: 100%;
        height: 50px;
        // background-color: pink;
        margin-top: 50px;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__main-area{
        width: 250px;
        height: 100%;
        // background-color: plum;
        text-align: center;
        @include flexColumn(center);
        color: $main-dark;
        cursor: pointer;
        transition: 0.3s;
        margin: 0px 10px 0px 10px;
        border-radius: 30px;
        &:hover{
            background-color: lighten($main-dark, 10%);
            color: white;
        }
        &:active{
            background-color: $main-dark;
        }
    }

    &__main-area_active{
        background-color: $main-dark;
        color: white;
        border-radius: 30px;
        &:hover{
            background-color: $main-dark;
        }
    }

    &__areas-block{
        width: 100%;
        // height: 500px;
        // background-color: yellow;
        margin-top: 20px;
        @include flexRow(center);
        flex-wrap: wrap;
        position: relative;
        display: none;
    }

    &__close-button{
        position: absolute;
        width: 30px;
        height: 30px;
        // background-color: red;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 30px);
        margin-top: -20px;
        // left: 100%;
        cursor: pointer;
        
        svg{
            transition: 0.3s;
        }

        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__area-block{
        width: 150px;
        height: 70px;
        text-align: center;
        text-decoration: none;
        // background-color: fuchsia;
        background-color: white;
        margin: 15px;
        color: $main-dark;
        @include flexColumn(center);
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.315);
        
        &:hover{
            transform: scale(1.1);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.411);
        }
    }
}

.action-section{
    padding: 50px 0px;
    width: 100%;
    // height: 300px;
    // background-color: red;
    background-color: darken(white, 3%);
    @include flexRow(center);

    &__container{
        padding:  25px 0px;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        // box-shadow: 0px 0px 0px 5px yellow;
        border: 3px dashed black;
        @include flexColumn(flex-start);
    }

    &__form-container{
        width: 100%;
        min-height: 100px;
        // background-color: pink;
        margin-top: 30px;
    }

    &__form{
        // background-color: plum;
        width: 100%;
        height: 100%;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
    }

    &__input{
        margin: 10px 10px;
        width: 250px;
        height: 40px;
        padding: 0px 0px 0px 5px;
        outline: none;
        border: 2px solid darken(black, 1%);
        caret-color: darken(yellow, 10%);
    }

    &__button{
        margin: 0px 10px;
        width: 259px;
        height: 44px;
        border: 0px;
        outline: none;
        background-color: yellow;
        // border: 2px solid black;
        transition: 0.1s;
        cursor: pointer;
        &:hover{
            background-color: darken(yellow, 5%);
        }
    }
}

.site-footer{
    padding: 50px 0px;
    width: 100%;
    // height: 300px;
    background-color: rgba(0, 0, 0, 0.685);
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: red;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__cats-block{
        margin: 20px 20px 0px 20px;
        height: 300px;
        width: 300px;
        // background-color: pink;
        @include flexRow(space-between);
        flex-wrap: wrap;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: yellow;
        }
    }
    
    &__cat{
        text-decoration: none;
        @include flexColumn(center);
        color: darken(white, 15%);
        width: 130px;
        // background-color: plum;
        margin-top: 5px;
        min-height: 30px;
        &:hover{
            color: white;
        }
    }
    
    &__contacts-block{
        margin: 20px 20px 0px 20px;
        max-width: 700px;
        width: 100%;
        height: 300px;
        // background-color: pink;
        @include flexColumn(flex-start);
        
    }

    &__contacts-container{
        width: 100%;
        min-height: 50px;
        // background-color: plum;
        @include flexRow(center);
        flex-wrap: wrap;
        align-items: center;
    }

    &__contact{
        // width: 100%;
        font-size: 17px;
        text-decoration: none;
        color: white;
        // height: 30px;
        // background-color: orange;
        margin-top: 5px;
        margin: 15px;
        transition: 0.1s;
        &:hover{
            text-decoration: underline;
        }
    }

    &__map-block{
        width: 100%;
        height: 250px;
        background-color: yellow;
        // margin-top: 30px;
    }
}

.services-section{
    padding: 50px 0px;
    width: 100%;
    // height: 500px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__table{
        width: 100%;
        margin-top: 50px;
    }

    tr{
        height: 30px;
        &:nth-child(odd){
            background-color: darken(white, 10%);
        }
    }

    th{
        // background-color: plum;
        // width: 30%;
    }

    &__name{
        background-color: $main-blue;
        width: 70%;
    }

    
    &__quantity{
        background-color: $main-blue;
        width: 15%;
    }

    &__price{
        background-color: $main-blue;
        width: 15%;
    }
    
    td{
        text-align: center;
    }
    &__link{
        // transition: 0.3s;
        // text-decoration: none;
        color: $main-dark;
        &:hover{
            color: $main-blue;
            transform: scale(1.1);
            // font-size: 17px;
        }
    }
}

.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__form{
        padding: 30px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 5px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 20px;
        height: 20px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 25px);
        bottom: calc(100% - 25px);
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
            }
        }
    }

    &__header{
        font-size: 20px;
        width: 200px;
    }

    &__user-input{
        margin-top: 20px;
        width: 200px;
        height: 30px;
        border: 1px solid $main-blue;
        border-radius: 5px;
        padding: 0px 0px 0px 5px;
        outline: none;
        font-size: 18px;
        font-weight: 300;
    }

    &__button{
        width: 210px;
        height: 40px;
        background-color: $main-dark;
        margin-top: 15px;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $main-blue;
        }
    }

    &__policy-text{
        width: 210px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: lightgrey;
    }
}

.up-button{
    width: 60px;
    height: 60px;
    border-radius: 100px 0px 0px 100px;
    background-color: white;
    box-shadow: 0px 0px 0px 5px $main-blue;
    // background-color: red;
    @include flexColumn(center);
    align-items: flex-start;
    // position: fixed;
    position: fixed;
    top: calc(95% - 65px);
    left: calc(100% - 60px);
    z-index: 100;
    display: none;

    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }
    svg{
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        width: 40px;
        height: 40px;
        path{
            fill: $main-blue;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

@keyframes modalCallAnim {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(10deg);}
    100%{transform: rotate(0deg);}
}

.call-button{
    box-shadow: 0px 0px 0px 5px white;
    width: 60px;
    height: 60px;
    border-radius: 0px 100px 100px 0px;
    background-color: white;
    box-shadow: 0px 0px 0px 5px $main-blue;
    @include flexColumn(center);
    align-items: flex-end;
    // position: fixed;
    position: fixed;
    top: calc(95% - 65px);
    right: calc(100% - 60px);
    z-index: 100;
    display: none;
    
    .modal-button__svg-container{
        width: 65px;
        height: 65px;
        border-radius: 65px;
        // background-color: white;
        @include flexColumn(center);
        align-items: center;
    }
    
    svg{
        // display: none;
        animation: modalCallAnim 1s infinite;
        // background-color: white;
        // padding: 13px;
        // border-radius: 30px;
        fill: $main-blue;
        width: 40px;
        height: 40px;
        path{
            fill: $main-blue;
        }
    }

    &:hover{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            animation-play-state: paused;
            transform: scale(1.1);
        }
    }

    &:active{
        .modal-button__svg-container{
            transform: scale(1.1);
        }
        svg{
            transform: scale(1.1);
        }
    }
}

.error-block{
    width: 100%;
    height: 90vh;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    text-align: center;

    &__container{
        @include flexColumn(center);
        // background-color: yellow;
        height: 100%;
    }

    &__message{
        font-size: 36px;
    }

    &__link{
        color: $main-blue;
        font-size: 36px;
    }
}

@media (max-width: 1220px){
    .site-header{
        // background-color: red;
        &__container{
            // background-color: yellow;
            justify-content: space-around;
            align-items: center;
            position: relative;
        }

        &__mobile-button{
            @include flexColumn(center);
            align-items: center;
        }

        &__nav{
            position: absolute;
            background-color: white;
            margin-top: 261px;
            width: 100%;
            @include flexRow(center);
            // display: none;
        }
        
        &__menu{
            padding: 0px 0px 10px 0px;
            @include flexColumn(flex-start);
            display: none;
            // background-color: orange;
        }

        &__menu-item{
            // background-color: pink;
            height: unset;
            margin-top: 10px;
        }

        &__order-button{
            margin-top: 10px;
            box-shadow: none;
        }
    }
}